export default function calcPageCount(results,varCurrentPage,itemsperpage)  {

    var varPerPage = itemsperpage                   //how many items to display
    var varPreviousPage = varCurrentPage -1         //default previous page
    var varNextPage = varCurrentPage +1             // default next page
    var varShowingStart = ((varCurrentPage * varPerPage)-varPerPage+1) //what number in the array index to start show items from
    varShowingStart = Math.max(varShowingStart,1)   // show the greater of 1, or existing value
    var varShowingEnd = varShowingStart + (varPerPage-1)    //what number in the array to stop showing items from
    var varShowingEnd = Math.min(results, varShowingEnd)    // show the lesser of the total, or existing value
    var varShowingTotal = results                           // the total number of results
    if (varShowingStart > varShowingEnd) {                  // override results if the start is higher than the end
        varShowingTotal = 0
    }
    var varShowingOnPage=0
    if (varShowingEnd>=varShowingStart) {
        varShowingOnPage = varShowingEnd-varShowingStart+1    //How many items are showing up on the current page (useful for placeholders)
    }

    //PAGE NUMBERS LOGIC
    var varPageCount = 1
    if (varShowingTotal >= varShowingEnd) {                  //we still have items to display

        //USE THE QUOTIENT
        varPageCount = Math.floor(varShowingTotal/varPerPage); //number of total pages is the total divided by the current page

        //SEE IF THERE IS A REMAINDER
        if (varShowingTotal % varPerPage > 0) {          //increment if there's a remainder
            varPageCount++
        }
    }

    return {
         varPageCount: varPageCount
        ,varShowingStart: varShowingStart
        ,varShowingEnd: varShowingEnd
        ,varShowingTotal: varShowingTotal
        ,varShowingOnPage: varShowingOnPage
        ,varPreviousPage: varPreviousPage
        ,varNextPage: varNextPage
       
    }
}