import Image from "next/image";
import { hasData } from "@/lib/utils/hasData";

export default function LinksAboutItem({ fieldIcon, fieldUrl, fieldName }) {
  const myLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality}`;
  };

  return (
    <div className="flex items-top content-top">
      <div className={`flex-0 `}>
        <div className="block max-w-sm  break-words text-ellipsis overflow-hidden ">
          {fieldName?.split("\n")?.map((line, index) => (
            <div key={index} className="min-h-[20px]">
              {line}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
