import SvgJsx from "@/components/templateux/svg/svg-jsx";
import Link from "next/link";
import { useEffect, useState } from "react";
import ProfileAvatar from "./profile-avatar";
import { getBitwise } from "@/lib/utils/bitoperations";
import { USER_PERMS } from "@/lib/config";

export default function ProfileSinglePlayReminder({
  itemdata,
  stream_url_active,
  player_state,
  set_player_state,
  set_showReminder,
  startPlayer,
}) {
  const [itemdata_active, set_itemdata_active] = useState(itemdata);

  useEffect(() => {
    set_itemdata_active(itemdata);
  }, [itemdata]);

  return (
    <>
      <div className=" animate-pulse h-24 pb-2 px-4 flex items-center content-center bg-red-600 hover:bg-red-500">
        <div className="flex-0">
          {/* AVATAR */}
          <div className={`flex-0 mt-3 ml-4`}>
            <Link
              href={`/${itemdata_active?.user_name?.toLowerCase()}`}
              className="cursor-pointer flex items-center content-center "
            >
              <ProfileAvatar
                // we disable the visibility of a broadcaster on public pages if they have notifications turned off...
                // the second part of this statement allows it still to be seen on their profile since we aren't
                // passing bol_send_notifications through all db calls.
                isPlaying={itemdata_active?.stream_status?.toString() == "1"}
                isContributor={itemdata_active?.contributor_level > 0}
                shrink={true}
                start_date={itemdata_active?.stream_start_date}
                timestamp={itemdata_active?.timestamp}
                placeholder={false}
                bol_caster={
                  !getBitwise(
                    itemdata_active?.credentials_bitarray,
                    Object.keys(USER_PERMS)
                  )["broadcastlive"]
                }
                avatar_url={itemdata_active?.avatar_url}
                avatar_url_sfw={itemdata_active?.avatar_url_sfw}
                bol_nsfw={itemdata_active?.bol_nsfw}
                avatar_crdate={itemdata_active?.avatar_crdate}
                id={itemdata_active?.id}
                user_name={itemdata_active?.user_name}
              />
            </Link>
          </div>
        </div>

        <button
          className="text-white  flex-1  p-2  cursor-pointer"
          onClick={() =>
            startPlayer(true, itemdata_active, player_state, set_player_state)
          }
        >
          <div className="flex items-center content-center justify-center mt-2">
            <div className="flex-0">
              <SvgJsx
                type={"fill"}
                icon={"play-sm"}
                className={` h-12 w-12 sm:h-16 sm:w-16 text-white rounded-full`}
                title={"play"}
              />
            </div>
            <div className="flex-0 ml-2">
              <div className="font-bold text-md lg:text-xl whitespace-nowrap">
                {itemdata_active?.user_name} is live!
              </div>

              <div>
                {/* {timer?.getTimeValues()?.toString(
                      parseInt(timer?.getTimeValues()?.toString(['days'])) > 0 
                      ? ['days','hours', 'minutes', 'seconds']
                      : ['hours', 'minutes', 'seconds']
                      )
                    } */}
              </div>
            </div>
          </div>
        </button>

        <button
          className="flex-0 pr-2  mt-2 cursor-pointer opacity-100 hover:opacity-75"
          //onClick={()=>startPlayer(false,itemdata_active,player_state,set_player_state)}
          onClick={() => set_showReminder(false)}
        >
          <SvgJsx
            type={"fill"}
            icon={"x-sm"}
            className={` h-5 w-5 text-white rounded-full `}
            title={"close"}
          />
        </button>
      </div>
    </>
  );
}
