import Link from "next/link";
import SvgJsx from "../templateux/svg/svg-jsx";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { useContext, useState } from "react";
import ProfileHeaderItemDropdown from "./item/profile-header-item-dropdown";

export default function ProfileHeaderItem({
  user_name,
  navtype,
  index,
  href,
  status,
  type,
  icon,
  short,
  className,
  classNameDropDownGroup = "",
  classNameDropDownItem = "",
  private_view,
  submenu,
  submenuarray,
  owner_id,
  user_id,
  profiledata,
  userdata,
}) {
  const visibility = useContext(VisibilityContext);

  if (submenu == 0) {
    return (
      <>
        <li tabIndex={0} id={`menu-element-${index}`} className="">
          <Link
            href={href}
            className={` group 
                                       ${
                                         private_view == 1
                                           ? `cursor-pointer `
                                           : status == 1
                                           ? ` cursor-pointer
                                               text-gray-100
                                            `
                                           : ` text-gray-700`
                                       } 
                                      `}
          >
            <div className={className}>
              <div className={` flex flex-row `}>
                <div className="flex-0">
                  <SvgJsx
                    type="outline"
                    icon={icon}
                    className={`  mr-2 h-6 w-6 hidden `}
                    title={short}
                  />
                </div>
                <div className="flex-1">
                  <div className="">{short}</div>
                </div>
              </div>
            </div>
          </Link>
        </li>
      </>
    );
  }

  if (submenu == 1) {
    return (
      <>
        <li tabIndex={0} id={`menu-element-${index}`} className="group ">
          <div
            className={`flex items-center content-center rounded-md overflow-hidden
                             ${classNameDropDownGroup}
                            
                            `}
          >
            <div
              className={`flex-1  font-bold rounded-l-md
                                ${classNameDropDownItem}
                              `}
            >
              <Link
                href={href}
                className={` 
                                         ${
                                           private_view == 1
                                             ? `cursor-pointer `
                                             : status == 1
                                             ? ` cursor-pointer
                                                text-gray-100
                                              `
                                             : ` text-gray-700`
                                         } 
                                        `}
              >
                <div
                  className={`px-4 pr-2 py-2 flex items-center content-center`}
                >
                  <div
                    className={`flex-0 ${
                      submenuarray[navtype]?.private == 1
                        ? "block rounded-full bg-red-600 h-4 w-4 p-0.5 mr-2"
                        : "hidden"
                    }`}
                  >
                    <SvgJsx
                      type="fill"
                      icon={
                        submenuarray[navtype]
                          ? submenuarray[navtype]?.icon
                          : icon
                      }
                      className={`   h-3 w-3 text-white `}
                      title={short}
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text-sm">
                      {submenuarray[navtype]
                        ? submenuarray[navtype]?.short
                        : short}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className={`flex-0 
                                          `}
            >
              <ProfileHeaderItemDropdown
                short={short}
                submenuarray={submenuarray}
                classNameDropDown={classNameDropDownItem}
                user_name={user_name}
                owner_id={owner_id}
                user_id={user_id}
                profiledata={profiledata}
                userdata={userdata}
              />
            </div>
          </div>
        </li>
      </>
    );
  }
}
