import Footer from "@/components/templateux/footer";
import LayoutPageBlank from "@/components/templateux/layout/layout-page-blank";
import NavPrimary from "@/components/templateux/nav/nav-primary";
import { config, meta } from "@/lib/config";
import { getProfileUrl } from "@/lib/utils/getProfileUrl";
import { hasData } from "@/lib/utils/hasData";
import { useContext, useEffect, useState } from "react";
import ProfileContainer from "./profile-container";
import ProfileSingleHeader from "./profile-single-header";
import { PlayerContext } from "@/components/context/player-context";
import { PushContext } from "@/components/context/push-context";
import { ModalContext } from "@/components/context/modal-context";
import { useInView } from "react-intersection-observer";

function ProfileLayout({
  navtype,
  navtype_header = "personal",
  subnavtype = navtype,
  helptype,
  ssr_data_tribe,
  profiledata_active,
  set_profiledata_active,
  showdata_active,
  set_showdata_active,
  ssr_data_chat,
  params,
  ssr_isadmin,
  page,
  sort,
  dir,
  user_name,
  owner_id,
  userdata,
  isUserDataLoading,
  isUserDataError,
  stream_url_active,
  broadcast_id,
  broadcastcache,
  set_broadcastcache,
  children,
  url = "",
  openModal = "",
  display_view,
  set_display_view,
  change_cache,
  set_change_cache,
  panel,
  changes,
  fullscreen_children = false,
}) {
  const ably = useContext(PushContext);

  const [titleRef, isOnScreen] = useInView({
    /* Optional options */
    threshold: 0,
    initialInView: true,
    //onChange:(inView)=>{console.log("view changed",inView)}
  });
  // const isOnScreenRef = useRef(isOnScreen);
  // isOnScreenRef.current = isOnScreen;

  //LISTENER WEBSOCKETS MONITORING
  // const [members_listeners, set_members_listeners] = useState([])
  const [channel_profile] = useState(
    `${ssr_data_tribe?.brand_id}-${config.websockets.listen}${profiledata_active?.id}`
  );
  const [channel_global] = useState(
    `${ssr_data_tribe?.brand_id}-${config.websockets.global}`
  );
  const { isOpen, set_isOpen } = useContext(ModalContext);
  const onMessage = (channel, rawmessage) => {
    let channel_string = `${ssr_data_tribe?.brand_id}-${config.websockets.listen}`;
    var channel_owner_id = channel?.name.replace(channel_string, "");
    let message = rawmessage;

    //messages
    if (["system"].indexOf(message?.type?.toString()) > -1) {
    }
  };

  const onMount = (channel, userdata, profiledata, channel_id) => {
    channel.subscribe((rawmsg) => {
      let msg = rawmsg?.data;
      // onMessage(channel, msg);
      //console.log("rawmessage:",channel_id,msg,owner_id?.toString(),msg?.id?.toString())

      //update the owner profile page if we are on it
      if (
        channel_id == channel_global &&
        owner_id?.toString() == msg?.id?.toString()
      ) {
        set_change_cache(+new Date());
      }
    });
  };

  const onUnmount = (channel) => {
    // channel?.presence?.leave();
    channel?.presence?.unsubscribe();
    channel?.unsubscribe();
  };

  useEffect(() => {
    if (userdata && !userdata.isLoading) {
      onMount(
        ably?.channels?.get(channel_profile),
        userdata,
        profiledata_active,
        channel_profile
      );
      onMount(
        ably?.channels?.get(channel_global),
        userdata,
        profiledata_active,
        channel_global
      );
    }
  }, [userdata]);

  useEffect(() => {
    return () => {
      onUnmount(ably?.channels?.get(channel_profile));
      onUnmount(ably?.channels?.get(channel_global));
    };
  }, []);

  //OLD STUFF

  const { player_state, set_player_state } = useContext(PlayerContext);

  const [showReminder, set_showReminder] = useState(false);

  useEffect(() => {
    set_showReminder(
      profiledata_active?.stream_status == 1 &&
        (player_state?.player_type !== "live" ||
          (player_state?.player_type == "live" &&
            (player_state?.content_id?.toString() == "0" ||
              player_state?.isHide == "1" ||
              !hasData(player_state?.content_id) ||
              (player_state?.content_id?.toString() !==
                profiledata_active?.broadcast_id?.toString() &&
                player_state?.content_table?.toString() == "post") || //xxx todo change this to Broadcast
              (player_state?.player_type == "live" &&
                player_state?.user_id !== profiledata_active?.id))))
    );
    return () => {};
  }, [
    profiledata_active?.stream_status,
    player_state?.player_type,
    player_state?.isHide,
    player_state?.content_id,
    player_state?.content_table,
    profiledata_active?.broadcast_id,
  ]);

  return (
    <>
      {/* MAIN CONTAINER */}
      <LayoutPageBlank
        title={`${profiledata_active?.user_name} | ${navtype}`}
        url={`${
          hasData(url)
            ? url
            : getProfileUrl(
                ssr_data_tribe?.server_website,
                profiledata_active?.user_name,
                navtype,
                page,
                `sort=${sort}&dir=${dir}`
              )
        }`}
        image={profiledata_active?.avatar_url}
        description={`${profiledata_active?.user_name}'s ${navtype} page on ${
          ssr_data_tribe?.brand_name
        } | ${profiledata_active?.user_description} | ${
          meta(ssr_data_tribe)?.description
        }`}
        keywords={`${profiledata_active?.user_name},${
          meta(ssr_data_tribe).keywords
        }`}
        ssr_data_tribe={ssr_data_tribe}
      >
        {/* NAVBAR */}
        <NavPrimary
          userdata={userdata}
          isUserDataLoading={isUserDataLoading}
          isUserDataError={isUserDataError}
          navtype={navtype}
          fixed={false}
          ssr_data_tribe={ssr_data_tribe}
          fullscreen_children={fullscreen_children}
        />
        {/* used to detect scrolling off the header */}
        <div className="h-[0px]  w-full " ref={titleRef}></div>
        {/* HEADER */}
        {!fullscreen_children && (
          <ProfileSingleHeader
            user_name={user_name}
            userdata={userdata}
            showReminder={showReminder}
            set_showReminder={set_showReminder}
            profiledata_active={profiledata_active}
            set_profiledata_active={set_profiledata_active}
            stream_url_active={stream_url_active}
            player_state={player_state}
            set_player_state={set_player_state}
            owner_block_json={profiledata_active?.block_json}
            owner_staff_json={profiledata_active?.staff_json}
            owner_bol_admin_help={profiledata_active?.bol_admin_help}
            owner_bol_staff_help={profiledata_active?.bol_staff_help}
            owner_id={owner_id}
            navtype={navtype}
            helptype={helptype}
          />
        )}

        {/* CONTAINER */}
        <ProfileContainer
          navtype={navtype}
          navtype_header={navtype_header}
          helptype={helptype}
          subnavtype={subnavtype}
          profiledata_active={profiledata_active}
          ssr_data_chat={ssr_data_chat}
          params={params}
          showdata_active={showdata_active}
          set_showdata_active={set_showdata_active}
          owner_id={owner_id}
          userdata={userdata}
          broadcast_id={broadcast_id}
          broadcastcache={broadcastcache}
          set_broadcastcache={set_broadcastcache}
          ssr_isadmin={ssr_isadmin}
          openModal={openModal}
          isOpen={isOpen}
          set_isOpen={set_isOpen}
          isHeaderOnScreen={isOnScreen}
          showReminder={showReminder}
          set_showReminder={set_showReminder}
          display_view={display_view}
          set_display_view={set_display_view}
          panel={panel}
          changes={changes}
          fullscreen_children={fullscreen_children}
        >
          {children}
        </ProfileContainer>
        {/* HIDE FOOTER WHEN CHAT FULL SCREEN IS ON TO PREVENT DARK MODE CONFLICTS */}
        {/* {(!fullScreen && navtype !== "chat") && */}
        {["replay"].indexOf(isOpen) == -1 && !fullscreen_children && <Footer />}
        {/* } */}
      </LayoutPageBlank>

      {/* </Html> */}
    </>
  );
}

export default ProfileLayout;
