import ProfileSection from "./profile-section";
import SubSection from "./subsection";
import { useContext } from "react";
import { TribeContext } from "../context/tribe-context";
import Image from "next/image";
import { Decorate } from "@/lib/utils/decorate";
import { srcSafe } from "@/lib/utils/srcSafe";

export default function ProfileAvatarBanner({
  profiledata,
  ssr_isadmin,
  show_name = true,
}) {
  const ssr_data_tribe = useContext(TribeContext);

  return (
    <>
      <div className="w-full">
        {profiledata?.bol_podcast_rss?.toString() == "1" && (
          <ProfileSection>
            <SubSection
              action_url={`/${profiledata?.user_name}/settings/personal?panel=avatar`}
              action_text={`edit`}
              action_condition={ssr_isadmin?.[`personal`]}
              htype={"h1"}
              headertitle={
                show_name ? (
                  <div className="">
                    <Decorate
                      text={profiledata?.user_name}
                      isDecorate={profiledata?.contributor_level > 0}
                      isReminder={false}
                    />
                  </div>
                ) : (
                  <>Photo</>
                )
              }
            >
              <div className={`text-sm relative w-full h-[256px]`}>
                <Image
                  src={srcSafe(profiledata?.avatar_url)}
                  layout="fill"
                  className="object-cover rounded-md"
                  alt={profiledata?.user_name}
                />
              </div>
            </SubSection>
          </ProfileSection>
        )}
      </div>
    </>
  );
}
