import Link from "next/link";
import Image from "next/legacy/image";
import { hasData } from "@/lib/utils/hasData";
import SubHeader from "./subheader";
import LinksAboutItem from "./links-about-item";
import { localUrlEval } from "@/lib/utils/localUrlEval";
import { useContext } from "react";
import { TribeContext } from "../context/tribe-context";
import SubSection from "./subsection";

export default function LinksAbout({
  links = [],
  headertitle = "Section Header",
  unique_id = `links`,
  ssr_isadmin,
  owner_name,
}) {
  const ssr_data_tribe = useContext(TribeContext);

  return (
    <>
      <div className="block   break-words text-ellipsis overflow-hidden">
        <SubSection
          action_url={`/${owner_name}/settings/profile?panel=userprofile#${unique_id}`}
          action_text={`edit`}
          action_condition={ssr_isadmin[`profile`]}
          headertitle={headertitle}
        >
          <ul
            className={`text-sm
                           text-gray-200 -mt-1`}
          >
            {(links.length == 0 ||
              (links.length == 1 &&
                !hasData(links[0].fieldName) &&
                !hasData(links[0].fieldUrl))) && (
              <li className="text-sm pt-1">Nothing here yet.</li>
            )}
            {links.map((e, index) => {
              return (
                <li className="text-sm mt-1 flex w-full" key={index}>
                  {/* LINKS OR TEXT OR BULLET */}
                  {(e[`fieldType`] == "links" ||
                    e[`fieldType`] == "text" ||
                    e[`fieldType`] == "bullet") && (
                    <>
                      {hasData(e[`fieldUrl`]) && (
                        <Link
                          href={localUrlEval(
                            e[`fieldUrl`],
                            ssr_data_tribe?.server_website
                          )}
                          target="_blank"
                          className={` block max-w-sm  break-all text-ellipsis 
                                    text-blue-400 hover:text-blue-100 hover:border-gray-700 underline hover:no-underline
                                  `}
                        >
                          <LinksAboutItem
                            fieldIcon={e[`fieldIcon`]}
                            fieldUrl={e[`fieldUrl`]}
                            fieldName={e[`fieldName`] || e[`fieldUrl`]}
                          />
                        </Link>
                      )}
                      {!hasData(e[`fieldUrl`]) && (
                        <LinksAboutItem
                          fieldIcon={e[`fieldIcon`]}
                          fieldUrl={e[`fieldUrl`]}
                          fieldName={e[`fieldName`]}
                        />
                      )}
                    </>
                  )}

                  {/* Header */}
                  {e[`fieldType`] == "header" && (
                    <div className="flex items-center content-center w-full mt-2">
                      <div
                        className={`flex-0 w-full
                                         text-gray-200
                                      `}
                      >
                        <h3 className="block max-w-sm  break-words text-ellipsis overflow-hidden font-bold text-md pb-1  w-full">
                          {e[`fieldName`]?.split("\n")?.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </h3>
                      </div>
                    </div>
                  )}

                  {/* Spacer */}
                  {e[`fieldType`] == "spacer" && (
                    <div className="items-center content-center block w-full h-2" />
                  )}

                  {/* Line */}
                  {e[`fieldType`] == "line" && (
                    <div className="items-center content-center block w-full mt-1 border-t  border-gray-700" />
                  )}
                </li>
              );
            })}
          </ul>
        </SubSection>
      </div>
    </>
  );
}
