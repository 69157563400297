import ProfileHeaderItem from "../profile/profile-header-item";

function SettingsMenuLayersSubmenu({
  profiledata,
  navtype,
  navtype_header,
  helptype,
  userdata,
  menukeys,
  size = "small",
  menu_view,
}) {
  return (
    <>
      <div
        className={`border-t border-b px-2 py-2 
               border-gray-700
                `}
      >
        <div className="flex items-center content-center">
          <div className="flex-1 overflow-hidden relative flex items-center content-center">
            {/* DESKTOP */}
            <ul
              className="flex list-none  gap-x-1 overflow-x-scroll no-scrollbar"

              // ref={ref}
              // onMouseDown={onMouseDown}
              // onDragStart={onMouseDown}
              // onDrag={onMouseDown}
            >
              {menukeys.map((e, index) => (
                <ProfileHeaderItem
                  key={index}
                  user_name={profiledata?.user_name}
                  navtype={navtype_header}
                  index={e}
                  href={`${
                    menu_view(profiledata?.user_name).menuarray[e]?.href
                  }`}
                  status={
                    menu_view(profiledata?.user_name).menuarray[e]?.status
                  }
                  type={menu_view(profiledata?.user_name).menuarray[e]?.type}
                  icon={menu_view(profiledata?.user_name).menuarray[e]?.icon}
                  short={menu_view(profiledata?.user_name).menuarray[e]?.short}
                  submenu={
                    menu_view(profiledata?.user_name).menuarray[e]?.submenu
                  }
                  submenuarray={
                    menu_view(profiledata?.user_name).menuarray[e]?.submenuarray
                  }
                  className={`px-2 py-1 rounded-md  ${
                    size == "big" ? " text-sm" : " text-xs"
                  }
                                      ${
                                        menu_view(
                                          profiledata?.user_name
                                        ).menuarray[e].type.indexOf(helptype) >
                                        -1
                                          ? `bg-gray-600  group-hover:bg-gray-800 `
                                          : `bg-gray-900 group-hover:bg-gray-800 
                                          `
                                      } 
                                    `}
                  private_view={0}
                  owner_id={profiledata?.id}
                  user_id={userdata?.id}
                  profiledata={profiledata}
                  userdata={userdata}
                />
              ))}
              <li>
                <div className="block " style={{ width: "0px" }}></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingsMenuLayersSubmenu;
