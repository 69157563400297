import { useContext, useEffect, useState } from "react";
import ProfileLinks from "../profile-links";
import ProfileRSS, { ProfileRssModal } from "../profile-rss";
import ProfileMenuTipJarNew from "./profile-menu-tip-jar-NEW";
import HeaderBar from "@/components/templateux/header-bar/header-bar";
import Tabs from "@/components/templateux/tabs";
import {
  settings_layer_submenu,
  settings_layer_submenu_noncreator,
  tabarray_follow_details,
  tabarray_posts_filter,
} from "@/lib/config";
import ProfileMenuTipJar from "./profile-menu-tip-jar";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { ModalAbout } from "@/components/templateux/modal/modal-about";
import { useRouter } from "next/router";
import { ProfileAbout } from "../profile-about";
import { getCacheDate } from "@/lib/utils/getCacheDate";
import ProfileShowSidebar from "../profile-show-sidebar";
import { TribeContext } from "@/components/context/tribe-context";
import { validTipJar } from "@/lib/utils/validTipJar";
import Link from "next/link";
import AvatarFlairClean from "@/components/templateux/user/avatar-flair-clean";
import { textProcess } from "@/lib/utils/textprocess";
import SettingsMenuLayersSubmenu from "@/components/settings/settings-menu-layers-submenu";
import SettingsRemoteButton from "@/components/settings/settings-remote-button";
import ProfileAvatarBanner from "../profile-avatar-banner";
import { embed_format } from "@/lib/utils/embedSrc";
import ProfileEmbed from "../profile-embed";

function ProfileContainer({
  navtype_header = "personal",
  navtype,
  helptype,
  subnavtype,
  profiledata_active,
  ssr_data_chat,
  params,
  showdata_active,
  set_showdata_active,
  owner_id,
  userdata,
  broadcast_id,
  broadcastcache,
  set_broadcastcache,
  children,
  ssr_isadmin,
  openModal,
  showReminder,
  set_showReminder,
  isOpen,
  set_isOpen,
  display_view,
  set_display_view,
  isHeaderOnScreen,
  panel,
  changes,
  fullscreen_children = false,
}) {
  const router = useRouter();
  const [cache, set_cache] = useState(getCacheDate());
  const ssr_data_tribe = useContext(TribeContext);

  useEffect(() => {
    set_isOpen(openModal);
  }, [openModal, set_isOpen]);

  //Admin Lookup

  const closeModal = async () => {
    set_isOpen("");
  };

  const set_tab_header = (n, url) => {
    //set_display_view(n);
    router.push(url, undefined, { shallow: true });
  };

  const about = (
    <>
      {validTipJar(profiledata_active) && (
        <div className="flex-0 visible md:hidden">
          <ProfileMenuTipJar profiledata={profiledata_active} />
        </div>
      )}
      <div
        onClick={() => set_isOpen("about")}
        className="ml-2 flex md:hidden text-gray-500 border-gray-700 hover:border-gray-400 border rounded-md cursor-pointer"
      >
        <SvgJsx
          type={`fill`}
          icon={`information-circle-sm`}
          className={` w-7 h-7 p-1 flex-0 my-auto  mx-auto`}
          title={"About"}
        />
      </div>
    </>
  );

  const avatar = () => (
    <div className="mr-3">
      <Link href={`/${profiledata_active?.user_name?.toLowerCase()}`}>
        <AvatarFlairClean
          isPlaying={false}
          isContributor={profiledata_active?.contributor_level > 0}
          isBot={false}
          avatar_url={profiledata_active?.avatar_url}
          avatar_url_sfw={profiledata_active?.avatar_url_sfw}
          bol_nsfw={profiledata_active?.bol_nsfw}
          avatar_crdate={profiledata_active?.avatar_crdate}
          id={profiledata_active?.id}
          user_name={profiledata_active?.user_name}
          bgClass="w-8 h-8"
          containerClass="w-8 h-7 "
          avatarClass="w-8 h-8 border-2 border-transparent "
          rotaryContainerClass="w-8 h-8 mr-4"
          rotaryInternalClass="w-8 h-9"
          sizes={{
            width: 36,
            height: 36,
            quality: 50,
          }}
        />
      </Link>
    </div>
  );

  return (
    <>
      {/* CONTAINER */}
      <div className="">
        {/* MAIN CONTENT */}
        <div className="flex-1">
          <div
            className="flex flex-row items-top mx-auto bg-gray-900
           border-x border-gray-700"
          >
            {/* MAIN CONTENT */}
            <div className="flex-1">
              {/* SHOW ALERTS FOR ALL BUT CHAT */}
              {/* {["chat", "replay"]?.indexOf(display_view) == -1 &&
                profiledata_active?.stream_status == "1" && (
                  <ShowAlertBanner user_name={profiledata_active?.user_name} />
                )} */}

              {["posts", "audio", "soundboards", "settings"]?.indexOf(
                display_view
              ) > -1 && (
                <div
                  className={` ${
                    ["replay", "admin"]?.indexOf(isOpen) > -1
                      ? ""
                      : "sticky top-[46px] z-100"
                  }`}
                >
                  <HeaderBar
                    headertitle={
                      <div className="flex items-center content-center">
                        <div>{isHeaderOnScreen ? <></> : <>{avatar()}</>}</div>
                        <div className="flex-1">
                          <Tabs
                            tabarray={tabarray_posts_filter(
                              profiledata_active?.user_name,
                              profiledata_active,
                              ssr_isadmin?.[helptype]
                            )}
                            clickval={display_view}
                            set_clickval={set_tab_header}
                            tab_style="selectboxhome"
                            isHeaderOnScreen={isHeaderOnScreen}
                          />
                        </div>
                      </div>
                    }
                    show_rss={false}
                    rss_link={``}
                    rss_text={""}
                    action_button={about}
                    show_action_button={
                      profiledata_active?.bol_creator?.toString() == "1" &&
                      ["settings"]?.indexOf(display_view) == -1
                    }
                    show_settings_save={
                      ["settings"]?.indexOf(display_view) > -1 && changes
                    }
                    settings_save={
                      <>
                        <SettingsRemoteButton panel={panel} />
                      </>
                    }
                  />
                </div>
              )}

              {["settings"].indexOf(display_view) > -1 && (
                <>
                  <SettingsMenuLayersSubmenu
                    profiledata={profiledata_active}
                    navtype={navtype}
                    navtype_header={navtype_header}
                    helptype={helptype}
                    userdata={userdata}
                    menukeys={
                      profiledata_active?.bol_creator?.toString() == "1"
                        ? Object.keys(
                            settings_layer_submenu(
                              profiledata_active?.user_name
                            ).menuarray
                          ).filter((e) => ssr_isadmin[e])
                        : Object.keys(
                            settings_layer_submenu_noncreator(
                              profiledata_active?.user_name
                            ).menuarray
                          ).filter((e) => ssr_isadmin[e])
                    }
                    size="small"
                    menu_view={
                      profiledata_active?.bol_creator?.toString() == "1"
                        ? settings_layer_submenu
                        : settings_layer_submenu_noncreator
                    }
                  />
                </>
              )}

              {["followers", "following"]?.indexOf(display_view) > -1 && (
                <div className="sticky top-[46px] z-100">
                  <HeaderBar
                    headertitle={
                      <div className="flex items-center content-center">
                        <div>{isHeaderOnScreen ? <></> : <>{avatar()}</>}</div>
                        <div className="flex-1">
                          <Tabs
                            tabarray={tabarray_follow_details(
                              profiledata_active?.user_name,
                              profiledata_active?.followers,
                              profiledata_active?.following
                            )}
                            clickval={display_view}
                            set_clickval={set_display_view}
                            tab_style="linkpill"
                          />
                        </div>
                      </div>
                    }
                    show_rss={false}
                    rss_link={``}
                    rss_text={""}
                    action_button={about}
                    show_action_button={
                      profiledata_active?.bol_creator?.toString() == "1"
                    }
                  />
                </div>
              )}

              {children}
            </div>

            {/* SIDEBAR */}
            {profiledata_active?.bol_creator?.toString() == "1" &&
              ["settings"].indexOf(display_view) == -1 &&
              ["chat", "replay"].indexOf(isOpen) == -1 &&
              !fullscreen_children && (
                <div className="  bg-gray-900 hidden md:block border-l  border-gray-700 ">
                  {validTipJar(profiledata_active) && (
                    <div
                      className="hidden md:flex items-center content-center border-b h-16 sticky top-[46px] z-100 border-gray-700  
                 bg-gray-800
                            
                            "
                    >
                      <div className="flex-1 justify-center w-full flex px-2 ">
                        <ProfileMenuTipJarNew
                          profiledata={profiledata_active}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="flex-0 md:w-64 
                            "
                  >
                    {/* USED TO MAKE THE RIGHT SIDE BORDER */}

                    <div
                      className="border-b 
                md:border-gray-700
                            border-transparent 
                            "
                    >
                      <div className="p-2">
                        <ProfileAvatarBanner
                          profiledata={profiledata_active}
                          ssr_isadmin={ssr_isadmin}
                        />
                      </div>

                      <div className="p-2 pt-0">
                        <ProfileRSS
                          profiledata={profiledata_active}
                          ssr_isadmin={ssr_isadmin}
                          set_isOpen={set_isOpen}
                          userdata={userdata}
                        />
                      </div>

                      {profiledata_active?.bol_description?.toString() ==
                        "1" && (
                        <div className="p-2 pt-0">
                          <ProfileAbout
                            profiledata={profiledata_active}
                            ssr_isadmin={ssr_isadmin}
                          />
                        </div>
                      )}

                      {showdata_active?.length > 0 && (
                        <div className="p-2 pt-0">
                          <ProfileShowSidebar
                            profiledata={profiledata_active}
                            showdata={showdata_active}
                            ssr_isadmin={ssr_isadmin}
                            userdata={userdata}
                            cache={cache}
                            set_cache={set_cache}
                          />
                        </div>
                      )}

                      <div className="p-2 pt-0">
                        <ProfileLinks
                          profiledata={profiledata_active}
                          ssr_isadmin={ssr_isadmin}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="  hidden md:flex h-16 px-5
                           
                            sticky top-10
                            "
                  >
                    <div className="flex items-center content-center flex-1 justify-center ">
                      <div className="flex justify-center my-4">
                        <div
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            // document.body.scrollTop = 0; // For Safari
                            // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                          }}
                          className="cursor-pointer bg-gray-600 hover:bg-gray-500 text-white font-bold text-sm italic px-4 py-1  rounded-full"
                        >
                          {textProcess(`Back to Top`)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <ModalAbout
        isOpen={isOpen == "embed"}
        closeModal={closeModal}
        profiledata={profiledata_active}
        ModalContent={
          <>
            <ProfileEmbed
              ssr_data_tribe={ssr_data_tribe}
              profiledata_active={profiledata_active}
              copy_content={embed_format(
                ssr_data_tribe?.server_website,
                `Live ${ssr_data_tribe?.marketing_seo_category} shows`,
                "1f2937", //bg
                "FF0404", //accent
                "FFFFFF", //text
                profiledata_active?.user_name?.toLowerCase(),
                "1",
                "100%",
                "100%",
                userdata?.id?.toString()
              )}
            />
          </>
        }
      />
      <ModalAbout
        isOpen={isOpen == "rss"}
        closeModal={closeModal}
        profiledata={profiledata_active}
        ModalContent={
          <>
            <ProfileRssModal
              userdata={userdata}
              rss_array={[
                {
                  title: `${profiledata_active?.user_name} audio RSS (podcasts)`,
                  url: `${
                    ssr_data_tribe?.server_website
                  }/${profiledata_active?.user_name?.toLowerCase()}/audio/rss/[dynamic_key]`,
                },
                {
                  title: `${profiledata_active?.user_name} posts RSS`,
                  url: `${
                    ssr_data_tribe?.server_website
                  }/${profiledata_active?.user_name?.toLowerCase()}/posts/rss/[dynamic_key]`,
                },
              ]}
            />
          </>
        }
      />
      <ModalAbout
        isOpen={isOpen == "about"}
        closeModal={closeModal}
        profiledata={profiledata_active}
        ModalContent={
          <div className="flex flex-col w-full">
            <div className="p-2 w-full">
              <ProfileAvatarBanner
                profiledata={profiledata_active}
                ssr_isadmin={ssr_isadmin}
                show_name={false}
              />
            </div>

            <div className="p-2 pt-0">
              <ProfileRSS
                profiledata={profiledata_active}
                ssr_isadmin={ssr_isadmin}
                set_isOpen={set_isOpen}
                userdata={userdata}
              />
            </div>

            <div>
              {profiledata_active?.bol_description?.toString() == "1" && (
                <div className="p-2 pt-0">
                  <ProfileAbout
                    profiledata={profiledata_active}
                    ssr_isadmin={ssr_isadmin}
                  />
                </div>
              )}

              {showdata_active?.length > 0 && (
                <div className="p-2 pt-0">
                  <ProfileShowSidebar
                    profiledata={profiledata_active}
                    showdata={showdata_active}
                    ssr_isadmin={ssr_isadmin}
                    userdata={userdata}
                    cache={cache}
                    set_cache={set_cache}
                  />
                </div>
              )}

              <div className="p-2 pt-0">
                <ProfileLinks
                  profiledata={profiledata_active}
                  ssr_isadmin={ssr_isadmin}
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}

export default ProfileContainer;
