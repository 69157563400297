import { useCallback, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SvgJsx from "../templateux/svg/svg-jsx";
import toast, { toastConfig } from "react-simple-toasts";

export default function ProfileEmbed({
  ssr_data_tribe,
  profiledata_active,
  copy_content,
}) {
  const [isMounted, set_isMounted] = useState(false);
  const [show_copied, set_show_copied] = useState(false);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  const copyFade = () => {
    set_show_copied(true);
    setTimeout(() => {
      set_show_copied(false);
    }, 2000);
  };

  const triggerToast = useCallback((msg) => {
    toastConfig({
      time: 5000,
      className: "",
      position: "right",
    });
    toast(msg, {
      time: 3000,
      className: "",
      clickable: true,
      clickClosable: false,
    });
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="w-full text-center font-bold mt-5">
          Copy to add this customizable player to your website!
        </div>
        <CopyToClipboard
          text={`${copy_content}`}
          onCopy={() => {
            triggerToast(`Copied to Clipboard`);
            copyFade();
          }}
        >
          <div className="w-full text-center mt-2 mb-5 px-4 flex items-center content-center">
            <div className="flex-1">
              <input
                className={`w-full cursor-pointer ${
                  show_copied
                    ? `bg-blue-600 text-white `
                    : `bg-gray-900 text-white`
                }`}
                value={copy_content}
              />
            </div>
            <div>
              <SvgJsx
                type={"outline"}
                icon={show_copied ? "check" : "clipboard"}
                className={`h-6 w-6 ml-2 cursor-pointer`}
                title={show_copied ? "Copied!" : "Copy to Clipboard"}
              />
            </div>
          </div>
        </CopyToClipboard>
        <div className="font-bold text-sm mt-5 text-center">Preview</div>
        <div className="w-full p-2">
          <iframe
            width="100%"
            height="230"
            src={`/app/embed/${profiledata_active?.user_name}`}
            frameBorder="0"
            allow=""
            className="border border-gray-700 rounded-md"
          />
        </div>
      </div>
    </>
  );
}
