import { useProfileData } from "./useProfileData";
import { useShowData } from "./useShowData";

export function useProfileCommonFn(
  ssr_data_profile,
  ssr_data_show,
  change_cache
) {
  const {
    profiledata_active,
    set_profiledata_active,
    profiledataisLoading,
    profiledataisError,
    showReminder,
    set_showReminder,
  } = useProfileData(ssr_data_profile, change_cache);

  const {
    showdata_active,
    set_showdata_active,
    showdataisLoading,
    showdataisError,
  } = useShowData(ssr_data_show, profiledata_active?.user_name, change_cache);

  return {
    profiledata_active: profiledata_active,
    set_profiledata_active: set_profiledata_active,
    profiledataisLoading: profiledataisLoading,
    profiledataisError: profiledataisError,
    showReminder: showReminder,
    set_showReminder: set_showReminder,
    showdata_active: showdata_active,
    set_showdata_active: set_showdata_active,
    showdataisLoading: showdataisLoading,
    showdataisError: showdataisError,
  };
}
