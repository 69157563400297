import { hasData } from "@/lib/utils/hasData";
import ProfileSection from "./profile-section";
import { memo, useContext } from "react";
import { TribeContext } from "../context/tribe-context";
import { markDownReact } from "@/lib/utils/markDown";
import SubSection from "./subsection";

function ProfileAboutNested({ profiledata, ssr_isadmin }) {
  const ssr_data_tribe = useContext(TribeContext);

  return (
    <>
      <div className="w-full">
        {profiledata?.bol_description == "1" && (
          <ProfileSection>
            <SubSection
              action_url={`/${profiledata?.user_name}/settings/profile?panel=userprofile#description`}
              action_text={`edit`}
              action_condition={ssr_isadmin[`profile`]}
              headertitle={"About"}
            >
              <div
                className={`
                                    text-sm
                                    text-gray-200
                                    break-words text-ellipsis overflow-hidden
                                    `}
              >
                {markDownReact(
                  hasData(profiledata?.user_description)
                    ? profiledata?.user_description
                    : "Nothing to see here",
                  ssr_data_tribe?.server_website
                )}
              </div>
            </SubSection>
          </ProfileSection>
        )}
      </div>
    </>
  );
}

export const ProfileAbout = memo(ProfileAboutNested);
