import { config } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
import { useUserAuth } from "@/lib/utils/hooks/useUserAuth";
import { useSession } from "next-auth/client";
import { useRouter } from "next/router";
import { Suspense, useContext, useEffect, useState } from "react";
import { PlayerContext } from "../context/player-context";
import ProfileLayout from "./item/profile-layout";
import ProfileMissing from "./profile-missing";
import { getStreamUrl } from "@/lib/utils/getStreamUrl";
import { textProcess } from "@/lib/utils/textprocess";

function ProfileHomeContainer({
  ssr_data_tribe,
  ssr_data_profile,
  ssr_data_show,
  ssr_data_chat = [],
  params,
  ssr_isadmin,
  navtype,
  navtype_header = "personal",
  helptype,
  display_view,
  set_display_view,
  change_cache,
  set_change_cache,
  panel,
  changes,
  show_children = true,
  hidden_children_message = `This section is not enabled for this account.`,
  fullscreen_children = false,
  children,
  showReminder,
  set_showReminder,
  profiledata_active,
  set_profiledata_active,
  profiledataisLoading,
  profiledataisError,
  showdata_active,
  set_showdata_active,
}) {
  const { player_state, set_player_state } = useContext(PlayerContext);

  const router = useRouter();

  const [cache, set_cache] = useState("");
  useEffect(() => {
    let cache_date = +new Date();
    let cache_date_encoded = encodeURIComponent(cache_date?.toString());
    set_cache(cache_date_encoded);
  }, []);

  const [user_name, set_user_name] = useState(params?.user_name?.toString()); //router?.query?.user_name?.toString()
  const [slug, set_slug] = useState("");
  const [page, set_page] = useState("1");
  const [sort, set_sort] = useState("date");
  const [dir, set_dir] = useState("up");

  useEffect(() => {
    if (router?.query) {
      set_user_name(router?.query?.user_name?.toString());
      set_slug(router?.query?.slug?.toString());
      set_page(router?.query?.page?.toString());
      set_sort(router?.query?.sort?.toString());
      set_dir(router?.query?.dir?.toString());
    }
  }, [router?.query]);

  // const [menukeys,set_menukeys] = useState(Object.keys(profile.menuarray));

  // const auth = useUserContext()
  const [session, isLoading] = useSession();

  const { userdata, isUserDataLoading, isUserDataError } = useUserAuth();

  //Chat states
  const [fullScreen, set_fullScreen] = useState(false);
  const [owner_id, set_owner_id] = useState(ssr_data_profile?.id);

  //follower action count
  const [followers_total, set_followers_total] = useState(
    ssr_data_profile?.followers_total
  );
  const [following_status, set_following_status] = useState(
    hasData(ssr_data_profile?.following_them) ? true : false
  );
  //const [user_name_h1,set_user_name_h1] = useState(user_name)

  //block action
  const [stream_url_active, set_stream_url_active] = useState("");

  useEffect(() => {
    set_fullScreen(false);
  }, []);

  useEffect(() => {
    set_stream_url_active(
      getStreamUrl(
        ssr_data_profile?.stream_url_active,
        ssr_data_profile?.stream_url,
        userdata?.listen_experience,
        player_state
      )
    );
  }, [
    ssr_data_profile?.stream_url_active,
    ssr_data_profile?.stream_url,
    userdata?.listen_experience,
  ]);

  //scrollable ref
  // const ref = useRef(null);

  //const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' });
  const [broadcast_id, set_broadcast_id] = useState(null);
  const [broadcastcache, set_broadcastcache] = useState(
    encodeURIComponent(+new Date())
  );

  useEffect(() => {
    if (!profiledataisLoading && !profiledataisError && profiledata_active) {
      set_owner_id(profiledata_active?.id);
      set_followers_total(profiledata_active?.followers);
      if (hasData(profiledata_active?.following_them))
        set_following_status(true);

      //lookup broadcast stats
      if (
        profiledata_active?.stream_status == 1 &&
        profiledata_active?.broadcast_id > 0
      ) {
        set_broadcast_id(profiledata_active?.broadcast_id);
      }

      //only update this once
      if (!profiledataisLoading && !profiledataisError && profiledata_active) {
        let new_url_active = getStreamUrl(
          profiledata_active?.stream_url_active,
          profiledata_active?.stream_url,
          userdata?.listen_experience,
          player_state
        );

        //only update this if it changed
        if (new_url_active !== stream_url_active)
          set_stream_url_active(new_url_active);
      }
    }
  }, [
    profiledata_active,
    profiledataisLoading,
    profiledataisError,
    stream_url_active,
    userdata?.listen_experience,
  ]);

  if (profiledataisError && profiledataisError?.status == "404") {
    return (
      <>
        <ProfileMissing
          user_name={user_name}
          userdata={userdata}
          isUserDataLoading={isUserDataLoading}
          isUserDataError={isUserDataError}
          navtype={navtype}
          helptype={helptype}
          ssr_data_tribe={ssr_data_tribe}
        />
      </>
    );
  }

  return (
    <>
      <Suspense fallback={<div className="text-white p-4">LOADING...</div>}>
        <ProfileLayout
          navtype={navtype}
          navtype_header={navtype_header}
          subnavtype={navtype}
          helptype={helptype}
          ssr_data_tribe={ssr_data_tribe}
          profiledata_active={profiledata_active}
          set_profiledata_active={set_profiledata_active}
          showdata_active={showdata_active}
          set_showdata_active={set_showdata_active}
          ssr_data_chat={ssr_data_chat}
          params={params}
          ssr_isadmin={ssr_isadmin}
          page={page}
          sort={sort}
          dir={dir}
          user_name={user_name}
          owner_id={owner_id}
          userdata={userdata}
          isUserDataLoading={isUserDataLoading}
          isUserDataError={isUserDataError}
          stream_url_active={stream_url_active}
          // player_state={player_state}
          // set_player_state={set_player_state}
          // fullScreen={fullScreen}
          broadcast_id={broadcast_id}
          broadcastcache={broadcastcache}
          set_broadcastcache={set_broadcastcache}
          display_view={display_view}
          set_display_view={set_display_view}
          change_cache={change_cache}
          set_change_cache={set_change_cache}
          panel={panel}
          changes={changes}
          openModal={
            profiledata_active?.bol_creator?.toString() == "1" &&
            profiledata_active?.bol_show_chat?.toString() == "1" &&
            navtype == "chat"
              ? "chat"
              : ""
          }
          fullscreen_children={fullscreen_children}
        >
          <div className="  text-white  bg-gray-900">
            {/* SLUG CONTENT */}
            {!show_children && (
              <div className="text-center my-4 font-bold">
                {textProcess(hidden_children_message)}
              </div>
            )}
            {show_children && children}
          </div>
        </ProfileLayout>
      </Suspense>
    </>
  );
}

export default ProfileHomeContainer;
