import { hasData } from "@/lib/utils/hasData";
import { useEffect, useState } from "react";
import LinksAbout from "./links-about";

export default function ProfileLinks({ profiledata, ssr_isadmin }) {
  //javascript objects - pulled from useEffect processing
  const [_user_friends_json, set_user_friends_json] = useState(
    hasData(profiledata?.user_friends_json)
      ? JSON.parse(profiledata?.user_friends_json)
      : { items: [] }
  );
  const [_user_contacts_json, set_user_contacts_json] = useState(
    hasData(profiledata?.user_contacts_json)
      ? JSON.parse(profiledata?.user_contacts_json)
      : { items: [] }
  );
  const [_user_links_json, set_user_links_json] = useState(
    hasData(profiledata?.user_links_json)
      ? JSON.parse(profiledata?.user_links_json)
      : { items: [] }
  );

  useEffect(() => {
    if (hasData(profiledata?.user_friends_json))
      set_user_friends_json(JSON.parse(profiledata?.user_friends_json));
    if (hasData(profiledata?.user_contacts_json))
      set_user_contacts_json(JSON.parse(profiledata?.user_contacts_json));
    if (hasData(profiledata?.user_links_json))
      set_user_links_json(JSON.parse(profiledata?.user_links_json));
  }, [profiledata]);

  return (
    <>
      {profiledata?.bol_links_json == "1" &&
      JSON.stringify(_user_links_json).length > 0 ? (
        <LinksAbout
          links={_user_links_json?.items}
          headertitle="More"
          ssr_isadmin={ssr_isadmin}
          owner_name={profiledata?.user_name}
        />
      ) : (
        <></>
      )}
    </>
  );
}
