import { RssLink } from "../rss-link";

export default function HeaderBar({
  headertitle,
  show_rss,
  rss_link,
  rss_text,
  show_action_button = true,
  action_button = <></>,
  show_border = true,
  show_bg = true,
  orientation = "left",
  border_class = `border-b border-gray-700`,
  show_settings_save = false,
  settings_save = <></>,
}) {
  return (
    <>
      <div className={`${show_bg ? "bg-gray-800" : ""}  w-full`}>
        <div
          className={`flex items-center justify-between ${
            show_border ? border_class : ""
          }  h-16 w-full
                              `}
        >
          <div className={`flex-1 flex items-center justify-between`}>
            <div
              className={` sm:flex flex-1 sm:items-center sm:justify-start sm:inset-y-0 sm:left-0 mx-2`}
            >
              <h2
                className={`flex-1 w-full font-extrabold inline-block text-xl lg:text-3xl`}
              >
                {headertitle}
              </h2>
            </div>

            <div className={`mr-4 flex-0 flex  justify-between`}>
              {show_action_button && (
                <div className={` flex-0 flex ml-1`}>{action_button}</div>
              )}

              {show_rss && (
                <div className="flex-0 flex ml-1 ">
                  <RssLink
                    show_rss={show_rss}
                    rss_link={rss_link}
                    rss_text={rss_text}
                  />
                </div>
              )}
              {show_settings_save && (
                <div className="flex-0 flex ml-1 ">{settings_save}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
