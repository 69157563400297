import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import { Fragment, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import PopupDropdown from "@/components/templateux/popup-dropdown";
import router from "next/router";
import { config } from "@/lib/config";
import calcPageCount from "@/lib/utils/calcPageCount";
import { isAdminObject } from "@/lib/utils/isAdminUser";

export default function ProfileHeaderItemDropdown({
  short,
  submenuarray,
  classNameDropDown,
  user_name,
  user_id,
  owner_id,
  profiledata,
  userdata,
}) {
  var menukeys = Object.keys(submenuarray);

  const [isMounted, set_isMounted] = useState(false);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {
    if (ref.current) ref.current.close();
  };
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const PopupTrigger = (
    <div
      className={` rounded-r-md font-bold cursor-pointer flex
                              ${classNameDropDown}
                              `}
    >
      <div className=" flex flex-1 p-1   border-gray-400">
        <SvgJsx
          type="fill"
          icon={"chevron-down-sm"}
          className={`  h-6 w-6 `}
          title={short}
        />
      </div>
    </div>
  );

  return (
    <div className="menu flex items-center content-center  flex-0 ">
      {!isMounted && PopupTrigger}
      {isMounted && (
        <Popup
          trigger={PopupTrigger}
          //position="bottom center"
          on="click"
          closeOnDocumentClick
          closeOnEscape
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{
            padding: "0px",
            border: "none",
            backgroundColor: "transparent",
          }}
          arrow={false}
          nested
          ref={isMounted ? ref : null}
        >
          <div
            className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2  mt-2
                    bg-gray-800 "
          >
            {/* ARRAY */}
            {menukeys.map((e, index) => (
              <Fragment key={index}>
                <PopupDropdown
                  isDisplay={
                    submenuarray[e].private == 0 ||
                    isAdminObject(
                      owner_id?.toString(), //user_id
                      profiledata?.bol_staff_help, //bol_staff_help
                      profiledata?.staff_json, //staff_json
                      profiledata?.bol_admin_help, //bol_admin_help
                      userdata?.id?.toString(), //my_id
                      userdata?.bol_admin?.toString() //global_admin
                    )[submenuarray[e]?.helptype]
                  }
                  onClick={() => {
                    closeMenu();
                    router.push(
                      `/${user_name}${submenuarray[e].href}${
                        e?.toString() == "chathistory"
                          ? `/${
                              calcPageCount(
                                profiledata?.total_chats,
                                1,
                                config.chat.displaymessageshistory
                              )?.varPageCount
                            }`
                          : ``
                      }`
                    );
                  }}
                  icon={submenuarray[e].icon}
                  title={submenuarray[e].short}
                  private_view={submenuarray[e].private}
                />
              </Fragment>
            ))}
          </div>
        </Popup>
      )}
    </div>
  );
}
