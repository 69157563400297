import { useState, useEffect, useContext } from "react";
import { useSWRWrapper } from "./swr-hooks";
import { config } from "@/lib/config";
import { validJson } from "../isJsonValid";

export function useShowData(ssr_data_show, user_name, change_cache) {
  const [showdata_active, set_showdata_active] = useState(ssr_data_show);

  //PROFILE DATA
  const {
    data: showdata,
    loading: showdataisLoading,
    error: showdataisError,
  } = useSWRWrapper(
    user_name, //id
    `/api/private/show/select-by-name?id=${user_name}&cache=${change_cache}${user_name}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  useEffect(() => {
    console.log(
      `/api/private/show/select-by-name?id=${user_name}&cache=${change_cache}${user_name}`
    );
  }, [
    `/api/private/show/select-by-name?id=${user_name}&cache=${change_cache}${user_name}`,
  ]);

  useEffect(() => {
    if (!showdataisLoading && !showdataisError && showdata) {
      set_showdata_active(validJson(showdata));
    }
  }, [showdata, showdataisLoading, showdataisError]);

  return {
    showdata_active: showdata_active,
    set_showdata_active: set_showdata_active,
    showdataisLoading: showdataisLoading,
    showdataisError: showdataisError,
  };
}
