export const embedSrc = (website, webtext, id) => {
  return (
    "const dt_script=document.getElementById('dt-embed');" +
    'if (!dt_script) throw Error("Could not find the script");' +
    "const dt_website=dt_script.getAttribute('data-website') || '" +
    website +
    "';" +
    "const dt_webtext=dt_script.getAttribute('data-webtext') || '" +
    webtext +
    "';" +
    "const dt_name=dt_script.getAttribute('data-name') || 'all';" +
    "const dt_bg=dt_script.getAttribute('data-bg') || '55555';" +
    "const dt_accent=dt_script.getAttribute('data-accent') || 'FF0404';" +
    "const dt_text=dt_script.getAttribute('data-text') || 'FFFFFF';" +
    "const dt_width=dt_script.getAttribute('data-width') || '100%';" +
    "const dt_height=dt_script.getAttribute('data-height') || '100%';" +
    "const dt_link=dt_script.getAttribute('data-link') || '1';" +
    "const iframe=document.createElement('iframe');" +
    "iframe.src=`${dt_website}/app/embed/" +
    id +
    "/${dt_name}?bg=${dt_bg}&accent=${dt_accent}&text=${dt_text}`;" +
    "iframe.width=dt_width;iframe.height=dt_height;" +
    "iframe.frameBorder='0';" +
    "iframe.scrolling='no';" +
    "document.body.appendChild(iframe);" +
    "if (dt_link === '1') {const dt_div=document.createElement('div');" +
    "const dt_small=document.createElement('small');" +
    "const dt_link=document.createElement('a');" +
    "dt_link.href=dt_website;" +
    "dt_link.textContent=dt_webtext;" +
    "dt_small.appendChild(dt_link);" +
    "dt_div.appendChild(dt_small);" +
    "document.body.appendChild(dt_div);}"
  );
};

export const embed_format = (
  website,
  webtext,
  bg,
  accent,
  text,
  name,
  bol_link,
  width,
  height,
  id
) => {
  // const display_content =
  // `<iframe src="${ssr_data_tribe?.server_website}/app/embed/${user_name}" frameborder="0" scrolling="no" width="100%"></iframe>`

  return (
    `<script type="text/javascript"` +
    ` data-website="${website}"` +
    ` data-webtext="${webtext}"` +
    ` data-bg="${bg}"` +
    ` data-accent="${accent}"` +
    ` data-text="${text}"` +
    ` data-name="${name}"` +
    ` data-width="${width}"` +
    ` data-height="${height}"` +
    ` data-link="${bol_link}"` +
    ` src="${website}/app/embed/scripts/${id}/embed.js"` +
    ` id="dt-embed"` +
    `></script><a href="${website}" target="_blank" ref="follow">${webtext}</a>`
  );
};
