export default function SettingsRemoteButton({ panel }) {
  return (
    <>
      <div className="flex items-center content-center justify-center">
        <label
          role="button"
          htmlFor={`submit-${panel}`}
          tabIndex={0}
          className="flex whitespace-nowrap cursor-pointer animate-save  text-white border border-transparent hover:border-white rounded-md  flex-0  text-center px-3 py-1.5 font-bold   shadow-md"
        >
          <span className="hidden sm:inline">Save changes</span>
          <span className="inline sm:hidden">Save</span>
        </label>
      </div>
    </>
  );
}
