import ProfileSection from "./profile-section";
import ShowArrayHome from "./show/homepage/show-array-home";
import SubSection from "./subsection";

export default function ProfileShowSidebar({
  profiledata,
  showdata,
  ssr_isadmin,
  userdata,
  cache,
  set_cache,
}) {
  return (
    <>
      <div className="w-full">
        <ProfileSection>
          <SubSection
            action_url={`/${profiledata?.user_name}/settings/broadcast`}
            action_text={`edit`}
            action_condition={ssr_isadmin[`profile`]}
            headertitle={"Schedule"}
          >
            <div className={`text-sm`}>
              <ShowArrayHome
                itemdata={showdata}
                itemmeta={null}
                isLoading={false} //{schedulearraydataisLoading}
                isError={false} //{schedulearraydataisError}
                nodata={"Nothing to show"}
              />
            </div>
          </SubSection>
        </ProfileSection>
      </div>
    </>
  );
}
