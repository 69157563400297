import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { config } from "@/lib/config";
import { getInterval } from "@/lib/utils/getInterval";
import { hasData } from "@/lib/utils/hasData";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import { textProcess } from "@/lib/utils/textprocess";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import Popup from "reactjs-popup";

function ProfileMenuTipJarNew({ profiledata, preview = false }) {
  const [open, set_open] = useState(false);
  const [isMounted, set_isMounted] = useState(false);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  const [_tip_jar_json, set_tip_jar_json] = useState(
    IsJsonValid(profiledata?.tip_jar_json)
      ? JSON.parse(profiledata?.tip_jar_json)
      : { items: [] }
  );

  const PopupTrigger = (
    <div className="flex-1 flex items-center  group cursor-pointer w-full">
      <div
        className={` font-bold text-lg text-center px-1 py-1 rounded-md flex flex-1 w-full
                                    bg-red-600 text-white group-hover:bg-red-500 max-w-[250px]
                                    ${
                                      getInterval(
                                        profiledata?.stream_end_date,
                                        profiledata?.timestamp
                                      ) < config.tip_jar.shake_interval &&
                                      getInterval(
                                        profiledata?.stream_end_date,
                                        profiledata?.timestamp
                                      ) !== -1
                                        ? ` animate-notice mr-2 `
                                        : ``
                                    }
                                    `}
      >
        <div
          role="button"
          className="flex-1 truncate whitespace-nowrap overflow-hidden max-w-[220px]"
          title={
            parseInt(profiledata?.contributor_level) > 0 &&
            hasData(profiledata?.tip_jar_text)
              ? profiledata?.tip_jar_text
              : config.tip_jar.text
          }
        >
          {textProcess(
            parseInt(profiledata?.contributor_level) > 0 &&
              hasData(profiledata?.tip_jar_text)
              ? profiledata?.tip_jar_text
              : config.tip_jar.text
          )}
        </div>
      </div>
    </div>
  );

  //non-contributor
  if (parseInt(profiledata?.contributor_level) <= 0) {
    return (
      <>
        <Link href={profiledata?.tip_jar_url}>{PopupTrigger}</Link>
      </>
    );
  }

  //contributor
  return (
    <div className="menu flex items-center content-center justify-center flex-1 ">
      {!isMounted && (
        <>
          {_tip_jar_json?.items?.length > 1 && (
            <>
              <div
                onClick={() => {
                  window.open(_tip_jar_json?.items[0]?.fieldUrl);
                }}
              >
                {PopupTrigger}
              </div>
            </>
          )}
        </>
      )}

      {isMounted && (
        <>
          {_tip_jar_json?.items?.length == 1 && (
            <div
              className="w-full"
              onClick={() => {
                window.open(_tip_jar_json?.items[0]?.fieldUrl);
              }}
            >
              {PopupTrigger}
            </div>
          )}

          {_tip_jar_json?.items?.length > 1 && (
            <>
              <Popup
                trigger={PopupTrigger}
                position="bottom right"
                //on="click"
                open={open}
                onOpen={() => set_open(true)}
                //onClose={()=>set_open('')}
                closeOnDocumentClick
                closeOnEscape
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "transparent",
                  zIndex: 1000,
                  width: "95%",
                  marginTop: "12px",
                }}
                arrow={false}
                nested
                //ref={isMounted ? ref : null}
              >
                <div
                  className={`menu ring-0 absolute right-[0px] w-full xs:w-64 `}
                >
                  <div
                    className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2 
                        bg-red-600 "
                  >
                    {_tip_jar_json?.items?.map((e, index) => (
                      <Fragment key={index}>
                        <PopupMenuOption
                          isDisplay={true}
                          onClick={() => {
                            window.open(e?.fieldUrl);
                          }}
                          icon="currency-dollar-sm"
                          title={e?.fieldName}
                          colorClass="bg-red-900 hover:bg-red-800 text-white"
                        />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </Popup>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ProfileMenuTipJarNew;
