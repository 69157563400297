import SvgJsx from "./svg/svg-jsx";

export default function PopupDropdown({
     isDisplay = true
    ,onClick = ()=>{}
    ,icon
    ,title
    ,pre_flare=<></>
    ,private_view
    ,children=<></>
}) {
    return (<>
    
    
    {(isDisplay) &&
    <div>
        <div className="menu-item">
                                
          <div 
            className={`bg-gray-900 hover:bg-gray-700 hover:text-gray-100 text-gray-200 
            cursor-pointer rounded-md flex items-center content-center justify-left w-full px-4 py-2 text-sm leading-5 text-left `}
            onClick={onClick}
          > 
            
            <div className={`absolute -ml-2 flex-0 justify-left text-sm ${private_view==1 ? 'block' : 'hidden'}`}>
              <SvgJsx
                type="fill"
                icon={icon}
                className={` h-3 w-3 ${private_view == 1 ? 'text-gray-400' : ''}`}
                title={title}
              />
            </div>
            <div className="mx-2 flex-1 whitespace-nowrap relative">
              {title}
            </div>
            <div className=" flex-0 whitespace-nowrap ">
              {pre_flare}
            </div>
          </div>
        </div>
        {children}
    </div>
    }
    
    </>)
}