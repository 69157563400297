import { useState, useEffect, useContext } from "react";
import { useSWRWrapper } from "./swr-hooks";
import { config } from "@/lib/config";
import { PlayerContext } from "@/components/context/player-context";
import { hasData } from "../hasData";

export function useProfileData(ssr_data_profile, change_cache) {
  const [profiledata_active, set_profiledata_active] =
    useState(ssr_data_profile);

  //PROFILE DATA
  const {
    data: profiledata,
    loading: profiledataisLoading,
    error: profiledataisError,
  } = useSWRWrapper(
    ssr_data_profile?.user_name, //id
    `/api/private/profile/select?id=${ssr_data_profile?.user_name}&cache=${change_cache}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    profiledata_active?.stream_status?.toString() == "1" //only refresh while stream is live
      ? config.profile.stream_interval_host
      : 0 //refreshinterval (0=none)
  );

  useEffect(() => {
    if (!profiledataisLoading && !profiledataisError && profiledata) {
      set_profiledata_active(profiledata);
    }
  }, [profiledata, profiledataisLoading, profiledataisError]);

  const { player_state, set_player_state } = useContext(PlayerContext);

  const [showReminder, set_showReminder] = useState(false);

  useEffect(() => {
    set_showReminder(
      profiledata_active?.stream_status == 1 &&
        (player_state?.player_type !== "live" ||
          (player_state?.player_type == "live" &&
            (player_state?.content_id?.toString() == "0" ||
              player_state?.isHide == "1" ||
              !hasData(player_state?.content_id) ||
              (player_state?.content_id?.toString() !==
                profiledata_active?.broadcast_id?.toString() &&
                player_state?.content_table?.toString() == "post") || //xxx todo change this to Broadcast
              (player_state?.player_type == "live" &&
                player_state?.user_id !== profiledata_active?.id))))
    );
    return () => {};
  }, [
    profiledata_active?.stream_status,
    player_state?.player_type,
    player_state?.isHide,
    player_state?.content_id,
    player_state?.content_table,
    profiledata_active?.broadcast_id,
  ]);

  return {
    profiledata_active: profiledata_active,
    set_profiledata_active: set_profiledata_active,
    profiledataisLoading: profiledataisLoading,
    profiledataisError: profiledataisError,
    showReminder: showReminder,
    set_showReminder: set_showReminder,
  };
}
